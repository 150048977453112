import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SEO from '../components/seo';
import Hero from '../components/Hero';
import '../styles/global.scss';
import FreshteamJobs from '../components/FreshteamJobs';
import CoreValues from '../components/CoreValues';
import PerksBenefits from '../components/PerksBenefits';
import CareersBottom from '../components/CareersBottom';
import 'normalize.css';
import Layout from '../components/Layout';

const Careers = () => {
  const data = useStaticQuery(
    graphql`
      query {
        ogImg: contentfulOpenGraph {
          ogimage {
            file {
              url
              fileName
            }
          }
        }
        contentfulCareerPage {
          hero {
            file {
              url
            }
          }
          subtitle
          title
          type
        }
      }
    `
  );
  const bannerImg = data.ogImg.ogimage[1].file.url;
  const type = data.contentfulCareerPage.type;

  return (
    <>
      <Layout>
        <SEO
          title='Careers'
          description={data.contentfulCareerPage.title}
          bannerImage={bannerImg}
          pathname='careers'
        />
        <Hero
          title={data.contentfulCareerPage.title}
          subtitle={data.contentfulCareerPage.subtitle}
          bgImage={data.contentfulCareerPage.hero.file.url}
          isProducts={true}
          isHomePage={true}
          isLetsTalk={true}
          isFull='is-fullheight-with-navbar'
          isCareers={true}
        />
        <FreshteamJobs />
        <CoreValues />
        <PerksBenefits />
        {/* Old Careers */}
        {/* <section className='section career-sec' id='some-id'>
          <img src={divider} alt='divider' />
          <h2 className='opp'>OPPORTUNITIES</h2>
          <h1 className='small'>Small team, global mission</h1>
          <TabGroup type={type} isCareers={true} />
        </section> */}
        <CareersBottom />
      </Layout>
    </>
  );
};

export default Careers;
