import React, { useEffect } from 'react';

const FreshteamJobs = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://s3.amazonaws.com/files.freshteam.com/production/79061/attachments/6000392920/original/6000017487_widget.js?1624966454";
        script.async = true
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return(
        <div id='freshteam-widget'></div>
    );
}

export default FreshteamJobs;