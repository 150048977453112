import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';
import { useStaticQuery, graphql } from 'gatsby';
import divider from '../images/divider-colored.svg';
import '../styles/global.scss';

const CoreValues = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulCareerPage {
        coreValues {
          id
          title
          description
          file {
            url
          }
        }
      }
    }
  `);

  const settings = {
    arrows: false,
    dots: true,
    pauseOnHover: false,
    infinite: true,
    speed: 5000,
    autoplaySpeed: 5000,
    initialSlide: 0,
    draggable: false,
    autoplay: true,
    zIndex: -1,
    rtl: false,
    className: 'center',
    // centerMode: true,
    // centerPadding: '160px',
    adaptiveHeight: true,
    variableWidth: false,
    slidesToShow: 1,
    swipeToSlide: true,
    slidesToScroll: 1,
  };

  return (
    <section className='' style={{ marginBottom: 25 }}>
      <div
        className='column is-full'
        style={{ marginTop: '40px', paddingBottom: '5px' }}
      >
        <center>
          <img src={divider} alt='divider' />

          <p className='SectionHeading' style={{ padding: 0, marginTop: 0 }}>
            Core Values
          </p>
          <p className='SectionSubHeading' style={{ fontSize: '15px' }}>
            We’re driven by curiosity, passion, optimisim and the belief that
            everybody can grow. Together, we can achieve anything.
          </p>
        </center>
      </div>
      <Slider {...settings}>
        {data.contentfulCareerPage.coreValues.map(data => (
          <div className='col-core'>
            <div key={data.id} className='columns '>
              <div className='column is-half'>
                <div className='is-hidden-mobile'>
                  <h1 className='coreval-title'>{data.title}</h1>
                  <p className='coreval-description'>{data.description}</p>
                </div>
              </div>
              <div className='column '>
                <img
                  className='coreval-img is-hidden-mobile'
                  src={data.file.url}
                  alt={data.title}
                />
              </div>
              <div className='is-hidden-desktop is-hidden-tablet'>
                <h1 className='coreval-title-mobile is-hidden-desktop is-hidden-tablet'>
                  {data.title}
                </h1>
                <img
                  className='coreval-img-mobile is-hidden-desktop is-hidden-tablet'
                  src={data.file.url}
                  alt={data.title}
                />
                <p className='coreval-description is-hidden-desktop is-hidden-tablet'>
                  {data.description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};

CoreValues.propTypes = {
  CoreValues: PropTypes.shape({
    map: PropTypes.func,
  }),
};

export default CoreValues;
