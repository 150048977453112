import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { animated, useSpring } from 'react-spring';
import Bounce from 'react-reveal/Bounce';
import divider from '../images/divider-colored.svg';

// You can use this `calc` method to increase the impact
// of the effect by playing around with the values and units.

const calc = o => `translateY(${o * 0.02}px)`;

const PerksBenefits = () => {
  const data = useStaticQuery(
    graphql`
      query {
        contentfulCareerPage {
          perksAndBenefitsHeading
          perksAndBenefits {
            file {
              url
              fileName
            }
            title
            description
          }
        }
      }
    `
  );

  const ref = useRef();
  const [{ offset }, set] = useSpring(() => ({ offset: 0 }));

  const handleScroll = () => {
    const posY = ref.current.getBoundingClientRect().top;
    const offset = window.pageYOffset - posY;
    set({ offset });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <section className='section perk-benefits-container'>
      <img src={divider} alt='divider' />
      <h2 className='opp'>Perks and Benefits</h2>
      <h2
        className='title-perks'
        style={{ marginBottom: '5px', paddingBottom: '5px', height: '35px' }}
      >
        {data.contentfulCareerPage.perksAndBenefitsHeading[0]}
      </h2>
      <h3 className='perk-benefits-sub-title'>
        {data.contentfulCareerPage.perksAndBenefitsHeading[1]}
      </h3>
      {data.contentfulCareerPage.perksAndBenefits.map((data, index) =>
        index % 2 === 0 ? (
          <div ref={ref} className='columns top-perks'>
            <>
              <div className='column left-col-prod'>
                <div className='title-perks'>
                  <img src={divider} alt='divider' />
                  <div> {data.title}</div>
                </div>
                <div className='prod-description'>
                  {data.description.split('\n').map((item, i) => (
                    <div key={i} className='prod-description-text'>
                      {item}
                    </div>
                  ))}
                </div>
              </div>
              <div className='column'>
                <animated.div style={{ transform: offset.interpolate(calc) }}>
                  <center>
                    <Bounce right>
                      <img
                        src={data.file.url}
                        className='img-perks-side'
                        alt={data.title}
                      />
                    </Bounce>
                  </center>
                </animated.div>
              </div>
            </>
          </div>
        ) : (
          <div className='columns is-centered is-center-odd'>
            <>
              <div className='column'>
                <animated.div style={{ transform: offset.interpolate(calc) }}>
                  <center>
                    <Bounce left>
                      <img
                        src={data.file.url}
                        className='img-perks-side'
                        alt={data.title}
                      />
                    </Bounce>
                  </center>
                </animated.div>
              </div>
              <div className='column left-col-prod'>
                <div className='title-perks'>
                  <img src={divider} alt='divider' />
                  <div> {data.title}</div>
                </div>
                <div className='prod-description'>
                  {data.description.split('\n').map((item, i) => (
                    <div key={i} className='prod-description-text'>
                      {item}
                    </div>
                  ))}
                </div>
              </div>
            </>
          </div>
        )
      )}
    </section>
  );
};

export default PerksBenefits;
